import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrMessages } from '../../helpers/toaster.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as JWT from 'jwt-decode';

@Component({
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  spinner = false;
  UNameError: string;
  validEmail: boolean;
  emailError: string;
  defineRole: any;
  imagesUrl = environment.imagesUrl;
  newmenu = [];
  menu2 = [];
  userId: any;
  formError: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toast: ToastrMessages,
    private route: ActivatedRoute,
  ) {
  
    this.userId=JWT(localStorage.getItem('authToken'));
    this.http.get(this.baseUrl + 'users/' + this.userId.id).subscribe(
      (response: any) => {
        this.f['uName'].setValue(response.body.userName);
        this.f['image'].setValue(response.body.image);
        this.f['email'].setValue(response.body.email);
        this.imgURL = this.imagesUrl + response.body.image;
  
      }, (error) => {
        this.toast.showToast(NbToastStatus.DANGER, 'Invalid User!', 'Please Check User Id');
      },
    );

  }
  baseUrl = environment.baseUrl;

  imagePath;
  fileData: File = null;
  eventForm: FormGroup;
  imgURL: any;
  message: string;
  ngOnInit() {
    this.eventForm = this.formBuilder.group({
      uName: ['', [Validators.required, Validators.minLength(4)]],
      image: [this.fileData],
      pwd: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    this.imgURL = 'https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo';
    this.f['image'].setValue('https://dummyimage.com/400x170/E9EFF4/000000&text=Main+Photo');
 
  }
  email(){
    if (this.f.email.invalid) {
    }
    else{
this.http.get(this.baseUrl + 'chaeckMail/'+ this.f.email.value).subscribe(
  (response: any) => {
    if(response.body=="Valid email"){
      this.emailError='';
    }
    else{
      this.emailError="err"
    }
  })
}
  }

  uName(){
    if (this.f.uName.invalid) {
     }
     else{
 this.http.get(this.baseUrl + 'chaeckUName/'+ this.f.uName.value).subscribe(
   (response: any) => {
     if(response.body=="Valid userName"){
       this.UNameError='';
     }
     else{
       this.UNameError="err"
     }
   })
 }

  }
  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    this.f['image'].setValue(files[0].name ? files[0].name : '');
    const reader = new FileReader();
    this.imagePath = files;
    this.fileData = <File>files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  // convenience getter for easy access to form fields
  get f() { return this.eventForm.controls; }
   ////// ========================== update profile data function =========================== //////
   updateprofile() {
  this.submitted = true;
  if(this.UNameError && this.emailError!=''){
    this.formError="Please input Valid data"
    return;
  }
  if (this.eventForm.invalid) {
    console.log("invalid")
    return;
  }

  // --------------------------------------------------------------          
  this.spinner = true;
  const formData = new FormData();
  formData.append('userName', this.f.uName.value);
 
  if(this.f.pwd.value !=''){
    formData.append('password', this.f.pwd.value);
  }
  formData.append('email', this.f.email.value);
  formData.append('image', this.fileData);
  formData.append('id', this.userId.id);
  if(this.fileData){
    formData.append('imagechnaged',"yes");
  }
  else{
    formData.append('imagechnaged',"no");
  }
  this.http.put(this.baseUrl + 'users', formData).subscribe(
    (response: any) => {
      this.spinner = false;
      if (response.message === 'Exists' || response.message === 'Error') {
        this.toast.showToast(NbToastStatus.DANGER, 'Error', response.body);
      } else if (response.message === 'Updated') {
        this.toast.showToast(NbToastStatus.SUCCESS, 'Profile', response.body);
        for (const i in this.eventForm.controls) {
          this.eventForm.controls[i].setErrors(null);
        }
      }
    },
    (error) => {
      this.spinner = false;
      this.toast.showToast(NbToastStatus.DANGER, 'Error', 'Something Went Wrong.Please Try Again!');
    });
}
}
